import { AmbidexChainId } from "../constants/networks";

export const DRAWER_WIDTH = 340;

export const QUERY_API = process.env.REACT_APP_QUERY_PROXY_API;

export type Network = "testnet" | "mainnet";

export const NETWORK: Network | undefined =
  process.env.REACT_APP_CHAIN_ID === AmbidexChainId.HEDERA_TESTNET.toString()
    ? "testnet"
    : process.env.REACT_APP_CHAIN_ID ===
      AmbidexChainId.HEDERA_MAINNET.toString()
    ? "mainnet"
    : undefined;

export const HEDERA_NODE_IDS = (
  process.env.REACT_APP_HEDERA_NODES || "3,4,5,6,7,8,9"
).split(",");

export const SUBMIT_TRANSACTION_GAS =
  Number(process.env.REACT_APP_SUBMIT_TRANSACTION_GAS) || 300_000;
export const REVOKE_TRANSACTION_GAS =
  Number(process.env.REACT_APP_REVOKE_TRANSACTION_GAS) || 200_000;
export const DEFAULT_CONFIRM_TRANSACTION_GAS =
  Number(process.env.REACT_APP_DEFAULT_CONFIRM_TRANSACTION_GAS) || 200_000;
export const DEFAULT_EXECUTE_TRANSACTION_GAS =
  Number(process.env.REACT_APP_DEFAULT_EXECUTION_TRANSACTION_GAS) || 500_000;
