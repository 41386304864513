import { AppBar, Box, Toolbar } from "@mui/material";
import { DRAWER_WIDTH } from "constants/index";
import React from "react";
import ConnectButton from "./ConnectButton";

const Header = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        ml: `${DRAWER_WIDTH}px`,
        background: "#fff",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: "18px",
          borderBottom: "1px solid rgba(0,0,0,0.08)",
        }}
      >
        <Box ml="auto">
          <ConnectButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
