import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

import { hashpack } from "../connectors";
import {
  DEFAULT_CONFIRM_TRANSACTION_GAS,
  DEFAULT_EXECUTE_TRANSACTION_GAS,
} from "../constants";
import { useWalletState } from "../state/wallet/hooks";
import {
  useRequiredConfirmations,
  useTransaction,
} from "state/transactions/hooks";
import { ACTIONS, ContractAction } from "constants/actions";

export interface ConfirmTransactionGasDialogProps {
  open: boolean;
  txId: number;
  onClose?: () => void;
}

export const ConfirmTransactionGasDialog: FC<
  ConfirmTransactionGasDialogProps
> = ({ open, txId, onClose }) => {
  const { account } = useWalletState();
  const { currentWalletAddress } = useMultiSigWallet();
  const requiredConfirmations = useRequiredConfirmations();

  const { isLoaded, transaction } = useTransaction();

  const action = useMemo(
    () =>
      ACTIONS.reduce<ContractAction[]>(
        (allActions, action) => [...allActions, ...action.actions],
        []
      ).find(
        (action) =>
          action.contract === transaction?.contractName &&
          action.function === transaction?.functionName
      ),
    [transaction]
  );

  const [gas, setGas] = useState<string>(
    (requiredConfirmations === (transaction?.confirmationCount || 0) + 1
      ? DEFAULT_CONFIRM_TRANSACTION_GAS +
        (action?.executionGasCost || DEFAULT_EXECUTE_TRANSACTION_GAS)
      : DEFAULT_CONFIRM_TRANSACTION_GAS
    ).toString()
  );

  useEffect(() => {
    if (requiredConfirmations === (transaction?.confirmationCount || 0) + 1) {
      setGas(
        (
          DEFAULT_CONFIRM_TRANSACTION_GAS +
          (action?.executionGasCost || DEFAULT_EXECUTE_TRANSACTION_GAS)
        ).toString()
      );
    }
  }, [action, transaction, requiredConfirmations]);

  const handleClickConfirm = async () => {
    if (!currentWalletAddress) return;
    await hashpack.confirmTransaction(currentWalletAddress, txId, account, gas);
    onClose?.();
  };

  const handleGasChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGas(event.target.value);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Set gas</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextField
            value={gas}
            onChange={handleGasChange}
            label="Gas"
            id="tx-gas"
            sx={{ marginY: 2 }}
            fullWidth
            type="number"
            disabled={!isLoaded}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={onClose}
          disabled={!isLoaded}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!gas || !isLoaded}
          onClick={handleClickConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
