import { AccountId } from "@hashgraph/sdk";
import { createAsyncThunk, createReducer, isAnyOf } from "@reduxjs/toolkit";
import {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
} from "@reduxjs/toolkit/dist/matchers";
import { ContractData } from "contexts/MultiSigWalletContext";
import { BigNumber, ethers } from "ethers";
import stringify from "fast-json-stable-stringify";
import MultiSigWalletABI from "../../abis/MultiSigWallet.json";
import { AppState } from "../../state";
import {
  getContractInterface,
  proxyAPI,
  getContractId,
  getContractLogs,
} from "../../utils";

export type ContractLog = {
  address: string;
  bloom: string;
  contract_id: string;
  data: string;
  index: number;
  topics: string[];
  block_hash: string;
  block_number: number;
  root_contract_id: string;
  timestamp: string;
  transaction_hash: string;
  transaction_index: number;
};

export type ContractLogResponse = {
  logs: ContractLog[];
  links: { next: string | null };
};

export type RawTransaction = {
  id: number;
  createdAt: number;
  executedAt?: number;
  executionFailedAt?: number;
  confirmedAccounts: string[];
};

type EventData = {
  name: string;
  args: ethers.utils.Result;
  block: number;
  timestamp: number;
};

export type Transaction = {
  txId: number;
  contractId: string;
  contractAddress: string;
  contractName: string;
  functionName: string;
  functionArgs: any;
  confirmationCount: number;
  confirmed: boolean;
  executed: boolean;
  failed: boolean;
  result: any;
  events: EventData[];
  value: string;
};

export interface TransactionsState {
  requiredConfirmations: number;
  transaction: Transaction | null;
  isLoadedTransaction: boolean;
  transactions: RawTransaction[];
  isLoadedTransactions: boolean;
  loadingKeys: Record<string, boolean>;
  isRequiredConfirmationsLoaded: boolean;
  fetchDataFailed: boolean;
}

const initialState: TransactionsState = {
  requiredConfirmations: 0,
  transactions: [],
  transaction: null,
  loadingKeys: {},
  isLoadedTransaction: false,
  isLoadedTransactions: false,
  isRequiredConfirmationsLoaded: false,
  fetchDataFailed: false,
};

const getTransactionById = async (
  contracts: Record<string, ContractData>,
  account: string,
  txId: number
): Promise<Transaction | null> => {
  const multiSigWalletAddress = contracts.MultiSigWallet.address;

  const { data: tx } = await proxyAPI.get(
    `/v2/wallet/${multiSigWalletAddress}/transactions/${txId}`
  );

  let confirmedAccounts: string[] = [];
  const events: EventData[] = [];
  let failed = false;

  const multiSigWalletInterface = new ethers.utils.Interface(MultiSigWalletABI);

  const logs = await getContractLogs(multiSigWalletAddress, {
    limit: 100,
    order: "desc",
  });

  for (const log of logs.reverse()) {
    const event = multiSigWalletInterface.parseLog(log);
    const { name, args } = event;

    if (args.some((arg) => arg.toString() === txId.toString())) {
      if (name === "Confirmation") {
        const sender = AccountId.fromSolidityAddress(args[0]).toString();
        confirmedAccounts.push(sender);
      } else if (name === "Revocation") {
        const sender = AccountId.fromSolidityAddress(args[0]).toString();
        confirmedAccounts = confirmedAccounts.filter(
          (account) => account !== sender
        );
      } else if (name === "ExecutionFailure") {
        failed = true;
      }
      events.push({
        name,
        args,
        block: log.block_number,
        timestamp: log.timestamp,
      });
    }
  }

  const confirmed = confirmedAccounts.includes(account);
  const confirmationCount = confirmedAccounts.length;

  const [destination, value, data, executed, result] = tx;

  if (data === "0x") {
    if (destination === ethers.constants.AddressZero) {
      return null;
    }

    // HBAR withdraw transaction
    return {
      txId,
      contractId: "",
      contractAddress: "",
      contractName: "HBAR",
      functionName: "Withdraw",
      functionArgs: [destination, BigNumber.from(value).toString()],
      confirmationCount,
      confirmed,
      executed,
      result,
      failed,
      events,
      value: ethers.utils.formatUnits(value, 8),
    };
  }

  const contractName = Object.keys(contracts).find(
    (key) => contracts[key].address.toLowerCase() === destination.toLowerCase()
  );
  const contractId = await getContractId(destination);

  const transaction = {
    txId,
    contractId,
    contractAddress: destination,
    contractName: "",
    functionName: "",
    functionArgs: [],
    confirmationCount,
    confirmed,
    executed,
    result,
    failed,
    events,
    value: ethers.utils.formatUnits(value, 8),
  };

  if (contractName) {
    const contractInterface = getContractInterface(contracts[contractName]);
    if (contractInterface) {
      const { args: functionArgs, name: functionName } =
        contractInterface.parseTransaction({ data, value });

      return { ...transaction, contractName, functionName, functionArgs };
    }
  } else {
    for (const key in contracts) {
      const iface = getContractInterface(contracts[key]);
      if (iface) {
        try {
          const { args: functionArgs, name: functionName } =
            iface?.parseTransaction({ data, value });
          return {
            ...transaction,
            contractName: key,
            functionName,
            functionArgs,
          };
        } catch (err) {}
      }
    }
  }

  return transaction;
};

export const fetchTransactionAsync = createAsyncThunk<
  Transaction | null,
  { contracts: Record<string, ContractData>; account: string; id: number },
  { state: AppState }
>("transactions/fetchTransactionAsync", async ({ contracts, account, id }) => {
  return await getTransactionById(contracts, account, id);
});

export const fetchTransactionsAsync = createAsyncThunk<
  RawTransaction[],
  { currentWalletAddress: string },
  { state: AppState }
>(
  "transactions/fetchTransactionsAsync",
  async ({ currentWalletAddress }) => {
    const iface = new ethers.utils.Interface(MultiSigWalletABI);

    if (!currentWalletAddress) {
      return [];
    }

    const logs = await getContractLogs(currentWalletAddress, {
      limit: 100,
      order: "desc",
    });

    const transactions: Record<string, Omit<RawTransaction, "id">> = {};

    for (const log of logs.reverse()) {
      const event = iface.parseLog(log);
      const { name, args } = event;

      if (name === "Submission") {
        const txId = args[0].toString();

        transactions[txId] = {
          createdAt: Number(log.timestamp),
          confirmedAccounts: [],
        };
      } else if (name === "Confirmation") {
        const sender = AccountId.fromSolidityAddress(args[0]).toString();
        const txId = args[1].toString();

        if (transactions[txId]) {
          transactions[txId].confirmedAccounts.push(sender);
        }
      } else if (name === "Revocation") {
        const sender = AccountId.fromSolidityAddress(args[0]).toString();
        const txId = args[1].toString();

        if (transactions[txId]) {
          transactions[txId].confirmedAccounts = transactions[
            txId
          ].confirmedAccounts.filter((account) => account !== sender);
        }
      } else if (name === "Execution") {
        const txId = args[0].toString();

        if (transactions[txId]) {
          transactions[txId].executedAt = Number(log.timestamp);
        }
      } else if (name === "ExecutionFailure") {
        const txId = args[0].toString();

        if (transactions[txId]) {
          transactions[txId].executionFailedAt = Number(log.timestamp);
        }
      }
    }

    return Object.entries(transactions)
      .map(([id, tx]) => ({
        id: Number(id),
        ...tx,
      }))
      .sort((tx1, tx2) => tx2.id - tx1.id);
  },
  {
    condition: (arg, { getState }) => {
      const { transactions } = getState();
      if (
        transactions.loadingKeys[
          stringify({ type: fetchTransactionsAsync.typePrefix, arg })
        ]
      ) {
        console.debug("transactions action is fetching, skipping here");
        return false;
      }
      return true;
    },
  }
);

export const fetchRequiredConfirmationsAsync = createAsyncThunk<
  number,
  { contracts: Record<string, ContractData> },
  { state: AppState }
>("transactions/fetchRequiredConfirmationsAsync", async ({ contracts }) => {
  const multiSigWalletAddress = contracts.MultiSigWallet.address;

  const {
    data: { value: requiredConfirmations },
  } = await proxyAPI.get(
    `/v2/wallet/${multiSigWalletAddress}/confirmations/required`
  );

  return requiredConfirmations;
});

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction;

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction["meta"]["requestStatus"]
) => {
  const type = action.type.split(`/${suffix}`)[0];
  return stringify({
    arg: action.meta.arg,
    type,
  });
};

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchTransactionsAsync.fulfilled, (state, action) => {
    state.transactions = action.payload;
    state.isLoadedTransactions = true;
  });
  builder.addCase(fetchTransactionAsync.fulfilled, (state, action) => {
    state.transaction = action.payload;
    state.isLoadedTransaction = true;
  });
  builder.addCase(
    fetchRequiredConfirmationsAsync.fulfilled,
    (state, action) => {
      state.requiredConfirmations = action.payload;
      state.isRequiredConfirmationsLoaded = true;
    }
  );

  builder.addCase(fetchTransactionsAsync.pending, (state) => {
    state.isLoadedTransactions = false;
  });

  builder.addCase(fetchTransactionAsync.pending, (state) => {
    state.isLoadedTransaction = false;
  });

  builder.addMatcher(
    isAnyOf(
      fetchTransactionsAsync.pending,
      fetchTransactionAsync.pending,
      fetchRequiredConfirmationsAsync.pending
    ),
    (state, action) => {
      state.loadingKeys[serializeLoadingKey(action, "pending")] = true;
    }
  );

  builder.addMatcher(
    isAnyOf(
      fetchTransactionsAsync.fulfilled,
      fetchTransactionAsync.fulfilled,
      fetchRequiredConfirmationsAsync.fulfilled
    ),
    (state, action) => {
      state.loadingKeys[serializeLoadingKey(action, "fulfilled")] = false;
    }
  );
  builder.addMatcher(
    isAnyOf(
      fetchTransactionsAsync.rejected,
      fetchTransactionAsync.rejected,
      fetchRequiredConfirmationsAsync.rejected
    ),
    (state, action) => {
      state.loadingKeys[serializeLoadingKey(action, "rejected")] = false;
      state.fetchDataFailed = true;
    }
  );
});
