import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import Circle from "../assets/blue-loader.svg";
import { Box } from "@mui/material";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled("img")`
  animation: 2s ${rotate} linear infinite;
  width: 16px;
  height: 16px;
`;

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

const Dots = styled("span")`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

export const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "35vh",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "24px",
      }}
    >
      <CustomLightSpinner
        src={Circle}
        alt="loader"
        size={"16px"}
        style={{ marginRight: "10px" }}
      />
      <Dots>Loading</Dots>
    </Box>
  );
};

export default Loader;
