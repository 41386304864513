import {
  AccountId,
  ContractExecuteTransaction,
  ContractFunctionParameters,
  ContractId,
  Hbar,
  TokenId,
} from "@hashgraph/sdk";
import BigNumberJS from "bignumber.js";
import { Action } from "components/Action";
import { hashpack } from "connectors";
import { SUBMIT_TRANSACTION_GAS } from "constants/index";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import { ethers } from "ethers";
import React from "react";
import { Helmet } from "react-helmet";
import { useWalletState } from "state/wallet/hooks";
import { getContractInterface, getToken } from "utils";

const TransactionCreatePage = () => {
  const { contracts, currentWalletAddress } = useMultiSigWallet();
  const { account } = useWalletState();

  const handleSubmitTransaction = async (
    contractName: string,
    functionName: string,
    contractAddress?: string,
    functionCost?: number,
    functionParams?: string
  ) => {
    if (!account || !currentWalletAddress) {
      return;
    }

    const contractInterface = getContractInterface(contracts[contractName]);

    if (!contractInterface) {
      return;
    }

    const multiSigWallet = ContractId.fromSolidityAddress(currentWalletAddress);

    try {
      const functionEncoded = contractInterface.encodeFunctionData(
        functionName,
        functionParams?.split(",") || []
      );

      const submitTransactionTx = new ContractExecuteTransaction()
        .setContractId(multiSigWallet)
        .setGas(SUBMIT_TRANSACTION_GAS)
        .setFunction(
          "submitTransaction",
          new ContractFunctionParameters()
            .addAddress(contractAddress || contracts[contractName].address)
            .addUint256(
              new BigNumberJS(
                new Hbar(functionCost || 0).toTinybars().toString()
              )
            )
            .addBytes(ethers.utils.arrayify(functionEncoded))
        );

      await hashpack.contractExecute(submitTransactionTx, account);
    } catch (err: any) {
      alert(err.message);
    }
  };

  const handleSubmitTransferToken = async (
    accountId: string,
    amount: number,
    tokenId?: string
  ) => {
    if (!account || !currentWalletAddress) {
      return;
    }

    const multiSigWallet = ContractId.fromSolidityAddress(currentWalletAddress);
    if (tokenId) {
      const tokenData = await getToken(tokenId);

      await handleSubmitTransaction(
        "HederaTokenService",
        "transferToken",
        undefined,
        undefined,
        `0x${TokenId.fromString(
          tokenId
        ).toSolidityAddress()},0x${multiSigWallet.toSolidityAddress()},0x${AccountId.fromString(
          accountId
        ).toSolidityAddress()},${amount * Math.pow(10, +tokenData.decimals)}`
      );
    } else {
      try {
        const submitTransactionTx = new ContractExecuteTransaction()
          .setContractId(multiSigWallet)
          .setGas(SUBMIT_TRANSACTION_GAS)
          .setFunction(
            "submitTransaction",
            new ContractFunctionParameters()
              .addAddress(AccountId.fromString(accountId).toSolidityAddress())
              .addUint256(
                new BigNumberJS(new Hbar(amount).toTinybars().toString())
              )
              .addBytes(new Uint8Array())
          );

        await hashpack.contractExecute(submitTransactionTx, account);
      } catch (err: any) {
        alert(err.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>New Transaction - Ambidex MultiSig</title>
      </Helmet>
      <Action
        onSubmit={handleSubmitTransaction}
        onSubmitTransferToken={handleSubmitTransferToken}
      />
    </>
  );
};

export default TransactionCreatePage;
