import { Link, Typography } from "@mui/material";
import { NETWORK } from "../constants";
import { FC } from "react";

export const Argument: FC<{ arg: any }> = ({ arg }) =>
  arg.toString().match(/0x[0-9a-fA-F]{40}/) ? (
    <Link
      onClick={(e) => {
        e.preventDefault();
        window.open(
          `https://hashscan.io/${NETWORK}/account/${arg.toString()}`,
          "_blank",
          "noopener,noreferrer"
        );
      }}
    >
      {arg.toString()}
    </Link>
  ) : (
    <Typography variant="body2">{arg.toString()}</Typography>
  );
