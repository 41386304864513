import { Box, Button, TableCell, TableRow } from "@mui/material";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { hashpack } from "../connectors";
import { useAppDispatch } from "../state";
import { useRequiredConfirmations } from "../state/transactions/hooks";
import {
  fetchTransactionsAsync,
  RawTransaction,
} from "../state/transactions/reducer";
import { useWalletState } from "../state/wallet/hooks";

dayjs.extend(localizedFormat);

type TransactionRowProps = {
  transaction: RawTransaction;
  onConfirm?: () => void;
  onExecute?: () => void;
};

export default function TransactionRow({
  transaction,
  onConfirm,
  onExecute,
}: TransactionRowProps) {
  const dispatch = useAppDispatch();
  const { currentWalletAddress } = useMultiSigWallet();
  const { id } = transaction;
  const requiredConfirmations = useRequiredConfirmations();
  const { account } = useWalletState();

  const handleConfirm = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (!account) {
      return;
    }

    onConfirm?.();
  };

  const handleExecute = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (!account) {
      return;
    }

    onExecute?.();
  };

  const handleRevoke = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (!account || !currentWalletAddress) return;

    await hashpack.revokeConfirmation(currentWalletAddress, id, account);
    dispatch(fetchTransactionsAsync({ currentWalletAddress }));
  };

  return transaction ? (
    <TableRow
      sx={{ cursor: "pointer" }}
      onClick={() => window.open(`/${currentWalletAddress}/transactions/${transaction.id}`)}
    >
      <TableCell>{transaction.id}</TableCell>
      <TableCell>
        {dayjs(transaction.createdAt * 1000).format("L LT")}
      </TableCell>
      <TableCell>
        {transaction.confirmedAccounts.length} / {requiredConfirmations}
      </TableCell>
      <TableCell>
        {transaction.executedAt
          ? `Executed at ${dayjs(transaction.executedAt * 1000).format("L LT")}`
          : transaction.executionFailedAt
          ? `Execution failed at ${dayjs(
              transaction.executionFailedAt * 1000
            ).format("L LT")}`
          : "Pending"}
      </TableCell>
      <TableCell>
        {!transaction.executedAt && (
          <Box sx={{ display: "flex", gap: 1 }}>
            {!transaction.confirmedAccounts.includes(account) ? (
              <Button variant="contained" color="info" onClick={handleConfirm}>
                Confirm
              </Button>
            ) : (
              <Button variant="outlined" color="error" onClick={handleRevoke}>
                Revoke
              </Button>
            )}
            {transaction.confirmedAccounts.length >= requiredConfirmations &&
              transaction.confirmedAccounts.includes(account) && (
                <Button variant="outlined" color="info" onClick={handleExecute}>
                  Execute
                </Button>
              )}
          </Box>
        )}
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
}
