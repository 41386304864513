import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface EventLog {
  address: string;
  bloom: string;
  contract_id: string;
  data: string;
  index: number;
  topics: string[];
  block_hash: string;
  block_number: number;
  root_contract_id: string;
  timestamp: string;
  transaction_hash: string;
  transaction_index: number;
}

interface ListResponse<T> {
  links: { next: string | null };
  logs: T[];
}

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_MIRROR_NODE_API }),
  endpoints: (builder) => ({
    listEvents: builder.query<ListResponse<EventLog>, string | null>({
      query: (link) => link ?? "",
    }),
  }),
});

export const { useListEventsQuery } = api;
