import AddresssProviderABI from "../abis/AddressProvider.json";
import CredentialManagerABI from "../abis/CredentialManager.json";
import HederaTokenServiceABI from "../abis/HederaTokenService.json";
import LaunchADXABI from "../abis/LaunchADX.json";
import LoanABI from "../abis/Loan.json";
import LoanStakingABI from "../abis/LoanStaking.json";
import LoanTreasuryABI from "../abis/LoanTreasury.json";
import MasterChefABI from "../abis/MasterChef.json";
import MultiSigWalletABI from "../abis/MultiSigWallet.json";
import StakingADXABI from "../abis/StakingADX.json";
import TreasuryABI from "../abis/Treasury.json";
import VestingADXABI from "../abis/VestingADX.json";
import UniswapV2PairABI from "../abis/UniswapV2Pair.json";

import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { NETWORK, Network } from "constants/index";
import { useParams } from "react-router-dom";

const functionRegex = /function [a-zA-Z]*\([^()]*\)( payable)?$/;

const fetchFunctions = (ABI: Array<string>) =>
  ABI.filter((fragment: string) => fragment.match(functionRegex)).map(
    (fragment: string) => fragment.replace("function ", "")
  );

export type ContractData = {
  address: string;
  abi: Array<string>;
  functions: Array<string>;
};

const CONTRACT_ADDRESS: Record<Network, Record<string, string>> = {
  testnet: {
    AddressProvider: "0x000000000000000000000000000000000037fc8e",
    CredentialManager: "0x000000000000000000000000000000000037fca6",
    LaunchADX: "0x0000000000000000000000000000000000006d21",
    MasterChef: "0x000000000000000000000000000000000037fcdc",
    MultiSigWallet: "0x000000000000000000000000000000000037fc96",
    StakingADX: "0x0000000000000000000000000000000000006d39",
    Treasury: "0x000000000000000000000000000000000037fcb1",
    UniswapV2Factory: "0x000000000000000000000000000000000037fce2",
    UniswapV2Router: "0x000000000000000000000000000000000037fce6",
    VestingADX: "0x0000000000000000000000000000000000006d2d",
  },
  mainnet: {
    AddressProvider: "0x00000000000000000000000000000000001edef2",
    CredentialManager: "0x00000000000000000000000000000000001edf06",
    LaunchADX: "0xffff000000000000000000000000000002ee4b89",
    MasterChef: "0x00000000000000000000000000000000001edf12",
    StakingADX: "0xffff000000000000000000000000000002ee4b91",
    Treasury: "0x00000000000000000000000000000000001edf0d",
    UniswapV2Factory: "0x00000000000000000000000000000000001edf24",
    UniswapV2Router: "0x00000000000000000000000000000000001edf9e",
    VestingADX: "0xffff000000000000000000000000000002ee4b8d",
  },
};

export const DEFAULT_CONTRACTS: Record<string, ContractData> = {
  AddressProvider: {
    address: NETWORK ? CONTRACT_ADDRESS[NETWORK].AddressProvider : "",
    abi: AddresssProviderABI,
    functions: fetchFunctions(AddresssProviderABI),
  },
  CredentialManager: {
    address: NETWORK ? CONTRACT_ADDRESS[NETWORK].CredentialManager : "",
    abi: CredentialManagerABI,
    functions: fetchFunctions(CredentialManagerABI),
  },
  HederaTokenService: {
    address: "0x0000000000000000000000000000000000000167",
    abi: HederaTokenServiceABI,
    functions: [],
  },
  LaunchADX: {
    address: NETWORK ? CONTRACT_ADDRESS[NETWORK].LaunchADX : "",
    abi: LaunchADXABI,
    functions: fetchFunctions(LaunchADXABI),
  },
  Loan: {
    address: "",
    abi: LoanABI,
    functions: fetchFunctions(LoanABI),
  },
  LoanTreasury: {
    address: "",
    abi: LoanTreasuryABI,
    functions: fetchFunctions(LoanTreasuryABI),
  },
  LoanStaking: {
    address: "",
    abi: LoanStakingABI,
    functions: fetchFunctions(LoanStakingABI),
  },
  MasterChef: {
    address: NETWORK ? CONTRACT_ADDRESS[NETWORK].MasterChef : "",
    abi: MasterChefABI,
    functions: fetchFunctions(MasterChefABI),
  },
  StakingADX: {
    address: NETWORK ? CONTRACT_ADDRESS[NETWORK].StakingADX : "",
    abi: StakingADXABI,
    functions: fetchFunctions(StakingADXABI),
  },
  Treasury: {
    address: NETWORK ? CONTRACT_ADDRESS[NETWORK].Treasury : "",
    abi: TreasuryABI,
    functions: fetchFunctions(TreasuryABI),
  },
  VestingADX: {
    address: NETWORK ? CONTRACT_ADDRESS[NETWORK].VestingADX : "",
    abi: VestingADXABI,
    functions: fetchFunctions(VestingADXABI),
  },
  UniswapV2Pair: {
    address: "",
    abi: UniswapV2PairABI,
    functions: fetchFunctions(UniswapV2PairABI),
  },
};

type MultiSigWalletContextType = {
  contracts: Record<string, ContractData>;
  multiSigWallets?: string[];
  currentWalletAddress?: string;
  setCurrentWalletAddress: Dispatch<SetStateAction<string | undefined>>;
};

const MultiSigWalletContext = createContext<MultiSigWalletContextType>({
  contracts: DEFAULT_CONTRACTS,
  setCurrentWalletAddress: () => {},
});

export const useMultiSigWallet = () => useContext(MultiSigWalletContext);

export const MultiSigWalletProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { walletAddress } = useParams();
  const [multiSigWallets, setMultiSigWallets] = useState<string[]>();
  const [currentWalletAddress, setCurrentWalletAddress] = useState<string>();

  useEffect(() => {
    const wallets = process.env.REACT_APP_MULTI_SIG_WALLETS?.split(",");
    setMultiSigWallets(wallets);
    if (wallets && wallets.length > 0) {
      setCurrentWalletAddress(wallets[0]);
    }
  }, []);

  return (
    <MultiSigWalletContext.Provider
      value={{
        contracts: {
          ...DEFAULT_CONTRACTS,
          MultiSigWallet: {
            address: (walletAddress ?? currentWalletAddress)!,
            abi: MultiSigWalletABI,
            functions: fetchFunctions(MultiSigWalletABI),
          },
        },
        multiSigWallets,
        currentWalletAddress: walletAddress ?? currentWalletAddress,
        setCurrentWalletAddress,
      }}
    >
      {children}
    </MultiSigWalletContext.Provider>
  );
};
