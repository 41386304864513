import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import {
  useFetchRequiredConfirmations,
  useFetchTransactions,
  useTransactions,
} from "../state/transactions/hooks";
import { ConfirmTransactionGasDialog } from "./ConfirmTransactionGasDialog";
import { ExecuteTransactionGasDialog } from "./ExecuteTransactionGasDialog";
import TransactionRow from "./TransactionRow";
import {
  fetchTransactionAsync,
  fetchTransactionsAsync,
} from "state/transactions/reducer";
import { useWalletState } from "state/wallet/hooks";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import { useAppDispatch } from "state";
import Loader from "./Loader";

export const Transactions = ({
  status,
}: {
  status: "executed" | "pending";
}) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [executeDialogOpen, setExecuteDialogOpen] = useState(false);
  const [selectedTxId, setSelectedTxId] = useState<number>(0);
  const { isLoaded, transactions: data } = useTransactions();
  const { account } = useWalletState();
  const { contracts, currentWalletAddress } = useMultiSigWallet();
  const dispatch = useAppDispatch();

  useFetchRequiredConfirmations();
  useFetchTransactions();

  const transactions = useMemo(
    () =>
      data.filter((tx) => {
        if (status === "executed") {
          return !!tx.executedAt;
        } else {
          return !tx.executedAt;
        }
      }),
    [data, status]
  );

  const handleConfirm = async (txId: number) => {
    setSelectedTxId(txId);
    setConfirmDialogOpen(true);
    dispatch(fetchTransactionAsync({ contracts, account, id: txId }));
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);

    if (currentWalletAddress) {
      dispatch(fetchTransactionsAsync({ currentWalletAddress }));
    }
  };

  const handleExecute = (txId: number) => {
    setSelectedTxId(txId);
    setExecuteDialogOpen(true);
    dispatch(fetchTransactionAsync({ contracts, account, id: txId }));
  };

  const handleCloseExecuteDialog = () => {
    setExecuteDialogOpen(false);

    if (currentWalletAddress) {
      dispatch(fetchTransactionsAsync({ currentWalletAddress }));
    }
  };

  return (
    <Box>
      {isLoaded ? (
        data.length > 0 ? (
          <Box sx={{ maxWidth: "1200px", mx: "auto", px: 3 }}>
            <Typography
              variant="h4"
              fontWeight={600}
              mb={3}
              sx={{ textTransform: "capitalize" }}
            >
              {status} Transactions
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography>
                {transactions.length} {status} transactions
              </Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                      ID
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                      Created At
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                      Confirmations
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                      Status
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((tx) => (
                    <TransactionRow
                      key={tx.id}
                      transaction={tx}
                      onConfirm={() => handleConfirm(tx.id)}
                      onExecute={() => handleExecute(tx.id)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <>No transactions</>
        )
      ) : (
        <Loader />
      )}
      <ConfirmTransactionGasDialog
        key={`confirm-txn-dialog-${selectedTxId}`}
        txId={selectedTxId}
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
      />
      <ExecuteTransactionGasDialog
        txId={selectedTxId}
        open={executeDialogOpen}
        onClose={handleCloseExecuteDialog}
      />
    </Box>
  );
};
