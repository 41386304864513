import {
  AccountId,
  ContractCallQuery,
  ContractExecuteTransaction,
  ContractFunctionParameters,
  ContractId,
  Transaction,
  TransactionId,
  TransactionReceipt,
} from "@hashgraph/sdk";
import { ConnectorUpdate } from "@web3-react/types";
import BigNumberJS from "bignumber.js";
import { HashConnect, HashConnectTypes, MessageTypes } from "hashconnect";

import {
  NetworkConnector,
  NetworkConnectorArguments,
} from "./NetworkConnector";
import { CURRENT_CHAIN_ID } from "../constants/networks";
import { HEDERA_NODE_IDS, NETWORK, REVOKE_TRANSACTION_GAS } from "../constants";

export class HashConnector extends NetworkConnector {
  private hashconnect: any;
  private status = "Initializing";

  availableExtensions: HashConnectTypes.WalletMetadata[] = [];
  installedExtension = false;

  saveData: {
    topic: string;
    pairingString: string;
    privateKey?: string;
    pairedWalletData?: HashConnectTypes.WalletMetadata;
    pairedAccounts: string[];
  } = {
    topic: "",
    pairingString: "",
    privateKey: undefined,
    pairedWalletData: undefined,
    pairedAccounts: [],
  };

  appMetadata: HashConnectTypes.AppMetadata = {
    name: "Ambidex",
    description: "A Hedera DeFi App",
    icon: "https://www.ambidex.fi/img/icons/adx_logo.png",
  };

  constructor(networkConnectArgs: NetworkConnectorArguments) {
    super(networkConnectArgs);
    if (!this.hashconnect) {
      this.hashconnect = new HashConnect();
    }
    this.hashconnect.foundExtensionEvent.on(this.foundExtensionEventHandler);
    this.initiateConnect();
  }

  foundExtensionEventHandler = () => {
    this.installedExtension = true;
  };

  saveDataInLocalstorage(state: any) {
    const data = JSON.stringify(state);

    localStorage.setItem("hashconnectData", data);
  }

  loadLocalData(): boolean {
    const foundData = localStorage.getItem("hashconnectData");

    if (foundData) {
      this.saveData = JSON.parse(foundData);
      console.log("Found local data", this.saveData);
      return true;
    } else return false;
  }

  clearPairings() {
    localStorage.removeItem("hashconnectData");
  }

  async contractCall(
    trans: ContractCallQuery,
    acctToSign: string
  ): Promise<string | Uint8Array | undefined> {
    let transactionBytes: Uint8Array = await trans.toBytes();

    const transaction: MessageTypes.Transaction = {
      topic: this.saveData.topic,
      byteArray: transactionBytes,
      metadata: {
        accountToSign: acctToSign,
        returnTransaction: false,
      },
    };

    const response = await this.hashconnect.sendTransaction(
      this.saveData.topic,
      transaction
    );

    if (response.success) return response.receipt;
  }

  async signAndMakeBytes(trans: Transaction, signingAcctId: string) {
    const transId = TransactionId.generate(signingAcctId);
    trans.setTransactionId(transId);
    trans.setNodeAccountIds(
      HEDERA_NODE_IDS.map((nodeId: string) => new AccountId(+nodeId))
    );
    await trans.freeze();
    const transactionBytes: Uint8Array = await trans.toBytes();
    return transactionBytes;
  }

  async contractExecute(
    trans: ContractExecuteTransaction,
    acctToSign: string
  ): Promise<TransactionReceipt | undefined> {
    const signedTransaction: Uint8Array = await this.signAndMakeBytes(
      trans,
      acctToSign
    );
    if (!signedTransaction) {
      throw new Error("Transaction not signed");
    }
    const response = await this.hashconnect.sendTransaction(
      this.saveData.topic,
      {
        topic: this.saveData.topic,
        byteArray: signedTransaction,
        metadata: {
          accountToSign: acctToSign,
          returnTransaction: false,
        },
      }
    );

    if (response.success)
      return TransactionReceipt.fromBytes(response.receipt as Uint8Array);
  }

  public async initiateConnect() {
    if (!localStorage.getItem("hashconnectData")) {
      const initData = await this.hashconnect.init(this.appMetadata);
      this.saveData = {
        topic: "",
        pairingString: "",
        privateKey: undefined,
        pairedWalletData: undefined,
        pairedAccounts: [],
      };
      this.saveData.privateKey = initData.privKey;
      const state = await this.hashconnect.connect();
      this.saveData.topic = state.topic;
      this.saveData.pairingString =
        await this.hashconnect.generatePairingString(state, NETWORK, true);
      this.hashconnect.findLocalWallets();
    }
  }

  public async activate(): Promise<ConnectorUpdate> {
    //create the hashconnect instance

    const initData = await this.hashconnect.init(this.appMetadata);
    this.saveData = {
      topic: "",
      pairingString: "",
      privateKey: undefined,
      pairedWalletData: undefined,
      pairedAccounts: [],
    };
    this.saveData.privateKey = initData.privKey;
    const state = await this.hashconnect.connect();
    this.saveData.topic = state.topic;
    this.saveData.pairingString = await this.hashconnect.generatePairingString(
      state,
      NETWORK,
      true
    );
    this.hashconnect.findLocalWallets();

    this.hashconnect.connectToLocalWallet(this.saveData.pairingString);

    return new Promise((resolve, reject) => {
      this.hashconnect.pairingEvent.on(async (data: any) => {
        const [account] = data.accountIds;
        const provider = await this.hashconnect.getProvider(
          NETWORK,
          state.topic,
          account
        );
        this.saveData.pairedWalletData = data.metadata;
        this.saveData.pairedAccounts.push(account);
        this.saveDataInLocalstorage({
          name: "Hashpack",
          chainId: CURRENT_CHAIN_ID,
          account,
          saveData: this.saveData,
        });

        resolve({
          provider,
          chainId: CURRENT_CHAIN_ID,
          account,
        });
      });
      // setTimeout(() => {
      //   reject()
      // }, 10000)
    });
  }

  async reconnect(saveData: any) {
    if (!this.hashconnect) {
      this.hashconnect = new HashConnect();
    }
    this.saveData = saveData;
    await this.hashconnect.init(this.appMetadata, saveData.privateKey);
    await this.hashconnect.connect(saveData.topic, saveData.pairedWalletData);
    this.hashconnect.findLocalWallets();
  }

  async sendTransaction(
    trans: Uint8Array,
    acctToSign: string,
    return_trans = false
  ) {
    const transaction: MessageTypes.Transaction = {
      topic: this.saveData.topic,
      byteArray: trans,

      metadata: {
        accountToSign: acctToSign,
        returnTransaction: return_trans,
      },
    };

    return await this.hashconnect.sendTransaction(
      this.saveData.topic,
      transaction
    );
  }

  // Multisig Wallet Methods
  public async confirmTransaction(
    walletAddress: string,
    id: number,
    account: string,
    gas: string | undefined
  ) {
    try {
      const multiSigWallet = ContractId.fromSolidityAddress(walletAddress);
      const confirmTransactionTx = new ContractExecuteTransaction()
        .setContractId(multiSigWallet)
        .setGas(Number(gas))
        .setFunction(
          "confirmTransaction",
          new ContractFunctionParameters().addUint256(new BigNumberJS(id))
        );

      await this.contractExecute(confirmTransactionTx, account);
    } catch (err: any) {
      alert(err.message);
    }
  }

  public async executeTransaction(
    walletAddress: string,
    id: number,
    account: string,
    gas: string | undefined
  ) {
    try {
      const multiSigWallet = ContractId.fromSolidityAddress(walletAddress);
      const executeTransactionTx = new ContractExecuteTransaction()
        .setContractId(multiSigWallet)
        .setGas(Number(gas))
        .setFunction(
          "executeTransaction",
          new ContractFunctionParameters().addUint256(new BigNumberJS(id))
        );

      await this.contractExecute(executeTransactionTx, account);
    } catch (err: any) {
      alert(err.message);
    }
  }

  public async revokeConfirmation(walletAddress: string, id: number, account: string) {
    if (!account) {
      return;
    }

    try {
      const multiSigWallet = ContractId.fromSolidityAddress(walletAddress);
      const revokeTransactionTx = new ContractExecuteTransaction()
        .setContractId(multiSigWallet)
        .setGas(REVOKE_TRANSACTION_GAS)
        .setFunction(
          "revokeConfirmation",
          new ContractFunctionParameters().addUint256(new BigNumberJS(id))
        );

      await this.contractExecute(revokeTransactionTx, account);
    } catch (err: any) {
      alert(err.message);
    }
  }
}
