import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { hashpack } from "connectors";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import dayjs from "dayjs";
import { useState } from "react";
import { useAppDispatch } from "state";
import { fetchTransactionAsync } from "state/transactions/reducer";
import { useWalletState } from "state/wallet/hooks";
import { NETWORK } from "../constants";
import {
  useFetchRequiredConfirmations,
  useFetchTransaction,
  useRequiredConfirmations,
  useTransaction,
} from "../state/transactions/hooks";
import { ConfirmTransactionGasDialog } from "./ConfirmTransactionGasDialog";
import { ExecuteTransactionGasDialog } from "./ExecuteTransactionGasDialog";
import { Argument } from "./Argument";
import Loader from "./Loader";

export const Transaction = ({ id }: { id: number }) => {
  const dispatch = useAppDispatch();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [executeDialogOpen, setExecuteDialogOpen] = useState(false);
  const [selectedTxId, setSelectedTxId] = useState<number>(0);
  const { transaction, isLoaded } = useTransaction();
  const { account } = useWalletState();
  const { contracts, currentWalletAddress } = useMultiSigWallet();
  const requiredConfirmations = useRequiredConfirmations();

  useFetchRequiredConfirmations();
  useFetchTransaction(id);

  const handleConfirm = () => {
    setSelectedTxId(id);
    setConfirmDialogOpen(true);
  };

  const handleExecute = () => {
    setSelectedTxId(id);
    setExecuteDialogOpen(true);
  };

  const handleRevoke = async () => {
    if (!currentWalletAddress) return;
    await hashpack.revokeConfirmation(currentWalletAddress, id, account);
    dispatch(fetchTransactionAsync({ contracts, account, id }));
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    dispatch(fetchTransactionAsync({ contracts, account, id }));
  };

  const handleCloseExecuteDialog = () => {
    setExecuteDialogOpen(false);
    dispatch(fetchTransactionAsync({ contracts, account, id }));
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ marginY: 2 }}>
      {isLoaded ? (
        transaction ? (
          <Box
            display="flex"
            gap={2}
            justifyContent="center"
            marginY={2}
            flexDirection="column"
          >
            <Box width="100%">
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{ fontWeight: 600, width: 200, fontSize: "16px" }}
                      >
                        Transaction Id
                      </TableCell>
                      <TableCell>{transaction.txId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{ fontWeight: 600, width: 200, fontSize: "16px" }}
                      >
                        Status
                      </TableCell>
                      <TableCell>
                        {transaction.executed
                          ? "Executed"
                          : transaction.failed
                          ? "Execution Failed"
                          : "Pending"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{ fontWeight: 600, fontSize: "16px" }}
                      >
                        Contract
                      </TableCell>
                      <TableCell>
                        <a
                          href={`https://hashscan.io/${NETWORK}/contract/${transaction.contractId}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {transaction.contractAddress}
                        </a>
                      </TableCell>
                    </TableRow>
                    {transaction.contractName && (
                      <TableRow>
                        <TableCell
                          component="th"
                          sx={{ fontWeight: 600, fontSize: "16px" }}
                        >
                          Contract Name
                        </TableCell>
                        <TableCell>{transaction.contractName}</TableCell>
                      </TableRow>
                    )}
                    {transaction.functionName && (
                      <TableRow>
                        <TableCell
                          component="th"
                          sx={{ fontWeight: 600, fontSize: "16px" }}
                        >
                          Contract Method
                        </TableCell>
                        <TableCell>{transaction.functionName}</TableCell>
                      </TableRow>
                    )}
                    {transaction.functionName && (
                      <TableRow>
                        <TableCell
                          component="th"
                          sx={{ fontWeight: 600, fontSize: "16px" }}
                        >
                          Contract Args
                        </TableCell>
                        <TableCell>
                          {transaction.functionArgs.map((arg: any, i: number) =>
                            Array.isArray(arg) ? (
                              arg.map((a: any, j: number) => (
                                <Argument
                                  key={`${transaction.functionName}-args-${i}-${j}`}
                                  arg={a}
                                />
                              ))
                            ) : (
                              <Argument
                                key={`${transaction.functionName}-arg-${i}`}
                                arg={arg}
                              />
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {transaction.value && (
                      <TableRow>
                        <TableCell
                          component="th"
                          sx={{ fontWeight: 600, fontSize: "16px" }}
                        >
                          Transaction Value
                        </TableCell>
                        <TableCell>{transaction.value} HBAR</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{ fontWeight: 600, fontSize: "16px" }}
                      >
                        # Confirmations
                      </TableCell>
                      <TableCell>{transaction.confirmationCount}</TableCell>
                    </TableRow>
                    {transaction.executed && (
                      <TableRow>
                        <TableCell
                          component="th"
                          sx={{ fontWeight: 600, fontSize: "16px" }}
                        >
                          Result
                        </TableCell>
                        <TableCell sx={{ wordBreak: "break-all" }}>
                          {transaction.result}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {!transaction.executed && (
                <Box mt={2} display="flex" gap={2}>
                  {!transaction.confirmed ? (
                    <Button
                      variant="contained"
                      color="info"
                      onClick={handleConfirm}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleRevoke}
                    >
                      Revoke
                    </Button>
                  )}
                  {transaction.confirmed &&
                    transaction.confirmationCount >= requiredConfirmations && (
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={handleExecute}
                      >
                        Execute
                      </Button>
                    )}
                </Box>
              )}
            </Box>
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                        Event Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                        Arguments
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                        Timestamp
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transaction.events.map((event, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          {event.name}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          {event.args.map((arg: any, i: number) =>
                            Array.isArray(arg) ? (
                              arg.map((a: any, j: number) => (
                                <Argument
                                  key={`${event.name}-args-${i}-${j}`}
                                  arg={a}
                                />
                              ))
                            ) : (
                              <Argument
                                key={`${event.name}-arg-${i}`}
                                arg={arg}
                              />
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          {dayjs(Number(event.timestamp) * 1000).format("L LT")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        ) : (
          <Box>Transaction does not exist</Box>
        )
      ) : (
        <Loader />
      )}
      <ConfirmTransactionGasDialog
        txId={selectedTxId}
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
      />
      <ExecuteTransactionGasDialog
        txId={selectedTxId}
        open={executeDialogOpen}
        onClose={handleCloseExecuteDialog}
      />
    </Box>
  );
};
