import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState, useAppDispatch } from "../../state";
import { useWalletState } from "../wallet/hooks";
import {
  fetchRequiredConfirmationsAsync,
  fetchTransactionAsync,
  fetchTransactionsAsync,
} from "./reducer";

export const useFetchTransactions = () => {
  const dispatch = useAppDispatch();
  const { account } = useWalletState();
  const { currentWalletAddress } = useMultiSigWallet();

  useEffect(() => {
    if (account && currentWalletAddress) {
      dispatch(fetchTransactionsAsync({ currentWalletAddress }));
    }
  }, [dispatch, account, currentWalletAddress]);
};

export const useFetchTransaction = (id: number) => {
  const dispatch = useAppDispatch();
  const { account } = useWalletState();
  const { contracts } = useMultiSigWallet();

  useEffect(() => {
    if (account) {
      dispatch(fetchTransactionAsync({ contracts, account, id }));
    }
  }, [dispatch, account, id]);
};

export const useFetchRequiredConfirmations = () => {
  const dispatch = useAppDispatch();
  const { contracts } = useMultiSigWallet();

  useEffect(() => {
    dispatch(fetchRequiredConfirmationsAsync({ contracts }));
  }, [dispatch]);
};

export const useTransactions = () => {
  const { transactions, isLoadedTransactions, isRequiredConfirmationsLoaded } =
    useSelector((state: AppState) => state.transactions);

  return {
    transactions,
    isLoaded: isLoadedTransactions && isRequiredConfirmationsLoaded,
  };
};

export const useTransaction = () => {
  const { transaction, isLoadedTransaction } = useSelector(
    (state: AppState) => state.transactions
  );

  return { transaction, isLoaded: isLoadedTransaction };
};

export const useRequiredConfirmations = () => {
  const transactions = useSelector((state: AppState) => state.transactions);

  return transactions.requiredConfirmations;
};
