import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import adxLogo from "assets/adx_logo_black.svg";
import bgImg from "assets/bg_connect_wallet.png";
import ConnectButton from "components/ConnectButton";
import { CURRENT_CHAIN_ID } from "constants/networks";
import { WHITELISTED_ACCOUNTS } from "constants/whitelist";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "state";
import { useWalletState } from "state/wallet/hooks";
import { setWalletFound } from "state/wallet/reducer";

function HomePage() {
  const { account, foundExtension } = useWalletState();
  const dispatch = useAppDispatch();
  const [walletAddress, setWalletAddress] = useState<string>("");
  const { multiSigWallets, currentWalletAddress, setCurrentWalletAddress } =
    useMultiSigWallet();

  const onMessage = (event: any) => {
    const { type, metadata } = event.data;
    if (type === "hashconnect-query-extension-response") {
      if (metadata) {
        dispatch(setWalletFound());
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", onMessage, false);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  if (!currentWalletAddress) return <></>;

  if (account && WHITELISTED_ACCOUNTS[CURRENT_CHAIN_ID].includes(account)) {
    return <Navigate to={`${currentWalletAddress}/events`} replace />;
  }

  return (
    <>
      <Helmet>
        <title>Ambidex MultiSig</title>
      </Helmet>
      <Box
        sx={{
          py: 5,
          height: "100%",
          minHeight: "100vh",
          width: "100%",
          background: `url(${bgImg}) center center / cover no-repeat`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <img src={adxLogo} alt="logo" />
          <Typography ml={2} fontSize={24} fontWeight={700}>
            MULTISIG
          </Typography>
        </Box>
        <Box
          sx={{
            my: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "560px",
            mx: "auto",
            px: 2,
            gap: 2,
          }}
        >
          <Select
            fullWidth
            value={currentWalletAddress}
            onChange={(e) => setCurrentWalletAddress(e.target.value)}
          >
            {multiSigWallets?.map((walletAddress) => (
              <MenuItem key={walletAddress} value={walletAddress}>
                {walletAddress}
              </MenuItem>
            ))}
          </Select>
          <Box display="flex" width="100%">
            <TextField
              variant="outlined"
              fullWidth
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
            />
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              disabled={!walletAddress}
              onClick={() => setCurrentWalletAddress(walletAddress)}
            >
              Select
            </Button>
          </Box>
        </Box>
        <Box my={3} display="flex" justifyContent="center">
          <ConnectButton disabled={!foundExtension} />
        </Box>
        {account &&
          !WHITELISTED_ACCOUNTS[CURRENT_CHAIN_ID].includes(account) && (
            <Typography textAlign="center">
              You are not whitelisted. You should be in the whitelist to use the
              app.
            </Typography>
          )}
      </Box>
    </>
  );
}

export default HomePage;
