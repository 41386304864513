import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ACTIONS, ActionType, ContractAction } from "constants/actions";
import { FC, useMemo, useState } from "react";
import { ContractActionDialog } from "./ContractActionDialog";
import { CreateTransactionDialog } from "./CreateTransactionDialog";

export type ActionProps = {
  onSubmit?: (
    contractName: string,
    functionName: string,
    contractAddress?: string,
    functionCost?: number,
    functionParams?: string
  ) => void;
  onSubmitTransferToken?: (
    accountId: string,
    amount: number,
    tokenId?: string
  ) => void;
};

export const Action: FC<ActionProps> = ({
  onSubmit,
  onSubmitTransferToken,
}) => {
  const [actionType, setActionType] = useState<ActionType>("Token");
  const [openCreateTransactionDialog, setOpenCreateTransactionDialog] =
    useState(false);
  const [contractAction, setContractAction] = useState<ContractAction>();
  const [openContractActionDialog, setOpenContractActionDialog] =
    useState(false);

  const actions = useMemo(
    () => ACTIONS.find(({ type }) => type === actionType)?.actions,
    [actionType]
  );

  const handleClickOpenTransactionDialog = () => {
    setOpenCreateTransactionDialog(true);
  };

  const handleCloseTransactionDialog = () => {
    setOpenCreateTransactionDialog(false);
  };

  const handleClickAction = (action: ContractAction) => {
    setContractAction(action);
    setOpenContractActionDialog(true);
  };

  const handleCloseContractActionDialog = () => {
    setOpenContractActionDialog(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      my={2}
    >
      <FormControl>
        <InputLabel id="action-type-label">Action Group</InputLabel>
        <Select
          labelId="action-type-label"
          label="Action Type"
          value={actionType}
          onChange={(e) => setActionType(e.target.value as ActionType)}
          sx={{ width: 300 }}
        >
          {ACTIONS.map(({ type }) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        flexGrow={1}
        gap={2}
        mt={4}
        mb={5}
      >
        {actions?.map((action) => (
          <Button
            key={action.name}
            variant="contained"
            onClick={() => handleClickAction(action)}
          >
            {action.name}
          </Button>
        ))}
      </Box>
      <Divider sx={{ width: '80%', mb: 5 }} />
      <Button
        variant="outlined"
        onClick={handleClickOpenTransactionDialog}
        sx={{ width: 300 }}
      >
        Manually Create Transaction
      </Button>
      <CreateTransactionDialog
        open={openCreateTransactionDialog}
        onClose={handleCloseTransactionDialog}
        onSubmit={onSubmit}
      />
      <ContractActionDialog
        open={openContractActionDialog}
        contractAction={contractAction}
        onClose={handleCloseContractActionDialog}
        onSubmit={onSubmit}
        onSubmitTransferToken={onSubmitTransferToken}
      />
    </Box>
  );
};
