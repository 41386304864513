import {
  AppBar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import adxLogoSvg from "assets/adx_logo.svg";
import { DRAWER_WIDTH } from "constants/index";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import React from "react";
import { useNavigate } from "react-router-dom";

const NAV_ITEMS = [
  { id: 0, title: "Events", path: "/:walletAddress/events" },
  {
    id: 1,
    title: "New Transaction",
    path: "/:walletAddress/transactions/new",
  },
  {
    id: 2,
    title: "Executed Transactions",
    path: "/:walletAddress/transactions/executed",
  },
  {
    id: 3,
    title: "Pending Transactions",
    path: "/:walletAddress/transactions/pending",
  },
];

const Sidebar = () => {
  const { currentWalletAddress } = useMultiSigWallet();
  const navigate = useNavigate();

  const handleNavItemClick = (path: string | undefined) => {
    if (!currentWalletAddress || !path) return;
    navigate(path.replace(":walletAddress", currentWalletAddress));
  };

  const renderNavItem = (root: any) => {
    if (!root.children) {
      return (
        <ListItem key={root.id} disablePadding sx={{ px: 2 }}>
          <ListItemButton onClick={() => handleNavItemClick(root.path)}>
            <ListItemText primary={root.title} />
          </ListItemButton>
        </ListItem>
      );
    }
    return (
      <ListItem
        key={root.id}
        disablePadding
        sx={{ flexDirection: "column", alignItems: "flex-start", px: 2 }}
      >
        <ListItemText
          primary={root.title}
          sx={{ px: 2, width: "100%", pt: 1 }}
        />
        <List sx={{ width: "100%" }}>
          {root.children.map((child: any) => renderNavItem(child))}
        </List>
      </ListItem>
    );
  };

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
          background: "#8b0000",
          color: "#fff",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          mx: 4,
          py: 3,
          borderBottom: "1px solid rgba(255,255,255,0.16)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <img src={adxLogoSvg} alt="logo" />
          <Typography lineHeight={1.2} ml={1}>
            MULTISIG
          </Typography>
        </Box>
      </Box>
      <List sx={{ py: 2 }}>{NAV_ITEMS.map((item) => renderNavItem(item))}</List>
    </Drawer>
  );
};

export default Sidebar;
