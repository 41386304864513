import { ContractId } from "@hashgraph/sdk";
import axios from "axios";
import { ContractData } from "contexts/MultiSigWalletContext";
import { ethers } from "ethers";
import { Interface } from "ethers/lib/utils";

export const proxyAPI = axios.create({
  baseURL: process.env.REACT_APP_QUERY_PROXY_API,
});

export const mirrorNodeAPI = axios.create({
  baseURL: process.env.REACT_APP_MIRROR_NODE_API,
});

export const getContractInterface = (
  contractData: ContractData
): Interface | null => {
  const { abi } = contractData;

  if (!abi) {
    return null;
  }

  return new ethers.utils.Interface(abi);
};

export async function getContractId(contractAddress: string) {
  return await mirrorNodeAPI
    .get<{ contract_id: string }>(`/api/v1/contracts/${contractAddress}`)
    .then(({ data }) => data.contract_id)
    .catch(() => "");
}

export const isMainNet = () => {
  return process.env.REACT_APP_CHAIN_ID === "1099";
};

export async function getToken(tokenId: string) {
  const { data } = await mirrorNodeAPI.get(`/api/v1/tokens/${tokenId}`);

  return data;
}

export const getContractLogs = async (
  contractIdOrAddr: string,
  params?: any
) => {
  let logs = [];

  let url = `/api/v1/contracts/${contractIdOrAddr}/results/logs?${new URLSearchParams(
    params
  ).toString()}`;

  const logLimit = process.env.REACT_APP_TXN_LOG_LIMIT;

  while (!logLimit || logs.length < +logLimit) {
    const data = await mirrorNodeAPI.get(url).then(({ data }) => data);

    logs.push(...data.logs);

    if (!data.links || !data.links.next) {
      break;
    }

    url = data.links.next;
  }

  return logs;
};

export const toContractAddress = (contractAddressOrId?: string) =>
  contractAddressOrId?.match(/0\.0\.[0-9]+/)
    ? "0x" + ContractId.fromString(contractAddressOrId).toSolidityAddress() // Automatic conversion of 0.0.x to address
    : contractAddressOrId;

export const toContractId = (contractAddressOrId?: string) =>
  contractAddressOrId?.match(/0x[0-9a-fA-F]{40}/)
    ? ContractId.fromSolidityAddress(contractAddressOrId).toString() // Automatic conversion of address to 0.0.x
    : contractAddressOrId;
