import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import { ChangeEvent, FC, useEffect, useState } from "react";

import { hashpack } from "../connectors";
import { DEFAULT_EXECUTE_TRANSACTION_GAS } from "../constants";
import { useWalletState } from "../state/wallet/hooks";
import { useTransaction } from "state/transactions/hooks";
import { ACTIONS, ContractAction } from "constants/actions";

export interface ExecuteTransactionGasDialogProps {
  open: boolean;
  txId: number;
  onClose?: () => void;
}

export const ExecuteTransactionGasDialog: FC<
  ExecuteTransactionGasDialogProps
> = ({ open, txId, onClose }) => {
  const { account } = useWalletState();
  const { currentWalletAddress } = useMultiSigWallet();
  const [gas, setGas] = useState<string>(
    DEFAULT_EXECUTE_TRANSACTION_GAS.toString()
  );
  const { isLoaded, transaction } = useTransaction();

  useEffect(() => {
    if (isLoaded && transaction) {
      const action = ACTIONS.reduce<ContractAction[]>(
        (allActions, action) => [...allActions, ...action.actions],
        []
      ).find(
        (action) =>
          action.contract === transaction?.contractName &&
          action.function === transaction.functionName
      );

      if (action?.executionGasCost) {
        setGas(action.executionGasCost.toString());
      }
    }
  }, [isLoaded, transaction]);

  const handleClickExecute = async () => {
    if (!currentWalletAddress) return;
    await hashpack.executeTransaction(currentWalletAddress, txId, account, gas);
    onClose?.();
  };

  const handleGasChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGas(event.target.value);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Set gas</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextField
            value={gas}
            onChange={handleGasChange}
            label="Gas"
            id="tx-gas"
            sx={{ marginY: 2 }}
            fullWidth
            type="number"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!gas}
          onClick={handleClickExecute}
        >
          Execute
        </Button>
      </DialogActions>
    </Dialog>
  );
};
