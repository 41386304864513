import { AmbidexChainId } from "./networks";

export type CredentialData = {
  name: string;
  data: string;
};

export const CREDENTIALS: { [chainId in AmbidexChainId]: CredentialData[] } = {
  [AmbidexChainId.HEDERA_TESTNET]: [
    {
      name: "DEX",
      data: "ipfs://QmR69q2J6BrqseM9WjY2BzpGXxi6H1Q5fzBjfP1yjbxPct",
    },
    {
      name: "Loan - Knightsbridge (Issuing)",
      data: "ipfs://QmXB7y3MEdi8rx4miDurx6rmo9a6nVSRr6oQCuxnyy6ES7",
    },
    {
      name: "Loan - Knightsbridge (Active)",
      data: "ipfs://QmXB7y3MEdi8rx4miDurx6rmo9a6nVSRr6oQCuxnyy6ES7",
    },
    {
      name: "Loan - Knightsbridge (Matured)",
      data: "ipfs://QmXB7y3MEdi8rx4miDurx6rmo9a6nVSRr6oQCuxnyy6ES7",
    },
  ],
  [AmbidexChainId.HEDERA_MAINNET]: [
    {
      name: "DEX",
      data: "ipfs://QmWC4NyuqR229UHBzf17wr2dnAptfzcJ4V4aGDVpmLyXPQ",
    },
    {
      name: "Loan - Knightsbridge",
      data: "ipfs://QmfFr4cVcQC3ZFBn49Y1Kr1DLgeq4d44SJ5ahcKFD3Ltfd",
    },
  ],
};
