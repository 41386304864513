import { CURRENT_CHAIN_ID } from "constants/networks";
import { WHITELISTED_ACCOUNTS } from "constants/whitelist";
import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useWalletState } from "state/wallet/hooks";

const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const { account } = useWalletState();

  if (!account || !WHITELISTED_ACCOUNTS[CURRENT_CHAIN_ID].includes(account)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
