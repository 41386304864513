import { ethers } from "ethers";
import { LOANS } from "./loan";
import { CURRENT_CHAIN_ID } from "./networks";
import { CREDENTIALS } from "./credentials";

export type FunctionArgument = {
  name: string;
  type: string;
  optional?: boolean;
  isMultiSigWallet?: boolean;
  defaultValue?: string;
  predefinedValues?: Array<{ name: string; value: string }>;
};

export type ContractAction = {
  name: string;
  contract: string;
  function: string;
  requireAddress?: boolean;
  preDefinedAddresses?: Array<{ name: string; address: string }>;
  requireCost?: boolean;
  executionGasCost?: number;
  arguments?: Array<FunctionArgument>;
};

export type ActionType =
  | "Credential"
  | "Token"
  | "Loan"
  | "LoanStaking"
  | "DEX"
  | "Farm"
  | "MultiSigWallet";

export type Action = {
  type: ActionType;
  actions: Array<ContractAction>;
};

export const ACTIONS: Array<Action> = [
  {
    type: "Credential",
    actions: [
      {
        name: "Mint",
        contract: "CredentialManager",
        function: "mintCredentialToken",
        executionGasCost: 800_000,
        arguments: [
          {
            name: "Recipient",
            type: "address",
          },
          {
            name: "Metadata",
            type: "string",
            predefinedValues: CREDENTIALS[CURRENT_CHAIN_ID].map(
              (credential) => ({
                name: credential.name,
                value: credential.data,
              })
            ),
          },
        ],
      },
      {
        name: "Wipe",
        contract: "CredentialManager",
        function: "wipeCredentialToken",
        executionGasCost: 800_000,
        arguments: [
          {
            name: "Owner",
            type: "address",
          },
          {
            name: "Serial Number",
            type: "int64",
          },
        ],
      },
    ],
  },
  {
    type: "Token",
    actions: [
      {
        name: "Associate",
        contract: "HederaTokenService",
        function: "associateToken",
        arguments: [
          {
            name: "Recipient",
            type: "address",
            isMultiSigWallet: true,
          },
          {
            name: "Token",
            type: "address",
          },
        ],
      },
      {
        name: "Withdraw",
        contract: "HederaTokenService",
        function: "transferToken",
        arguments: [
          {
            name: "Token",
            type: "address",
            optional: true,
          },
          {
            name: "Sender",
            type: "address",
            isMultiSigWallet: true,
          },
          {
            name: "Recipient",
            type: "address",
          },
          {
            name: "Amount",
            type: "int64",
          },
        ],
      },
    ],
  },
  {
    type: "Loan",
    actions: [
      {
        name: "Start Sale",
        contract: "Loan",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loan,
        })),
        function: "startLoanSale",
      },
      {
        name: "Stop Sale",
        contract: "Loan",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loan,
        })),
        function: "stopLoanSale",
      },
      {
        name: "Activate",
        contract: "Loan",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loan,
        })),
        function: "activateLoan",
      },
      {
        name: "Cancel",
        contract: "Loan",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loan,
        })),
        function: "cancelLoan",
      },
      {
        name: "Defaulted",
        contract: "Loan",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loan,
        })),
        function: "defaultedLoan",
      },
      {
        name: "Set Redeemable",
        contract: "Loan",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loan,
        })),
        function: "setLoanRedeemable",
        arguments: [
          {
            name: "Redeemable Percentage",
            type: "uint256",
          },
        ],
      },
      {
        name: "Set Duration",
        contract: "Loan",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loan,
        })),
        function: "setLoanDuration",
        arguments: [
          {
            name: "Duration",
            type: "uint256",
          },
        ],
      },
      {
        name: "Freeze Treasury",
        contract: "LoanTreasury",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loanTreasury,
        })),
        function: "freezeWithdrawal",
      },
      {
        name: "Unfreeze Treasury",
        contract: "LoanTreasury",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loanTreasury,
        })),
        function: "unfreezeWithdrawal",
      },
    ],
  },
  {
    type: "LoanStaking",
    actions: [
      {
        name: "Add reward",
        contract: "LoanStaking",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loanStaking,
        })),
        function: "addReward",
        arguments: [
          {
            name: "Staker",
            type: "address",
          },
          {
            name: "Amount",
            type: "uint256",
          },
        ],
      },
      {
        name: "Batch add rewards",
        contract: "LoanStaking",
        requireAddress: true,
        preDefinedAddresses: LOANS[CURRENT_CHAIN_ID].map((loan) => ({
          name: loan.name,
          address: loan.loanStaking,
        })),
        function: "batchAddRewards",
        executionGasCost: 1_000_000,
        arguments: [
          {
            name: "Stakers",
            type: "address[]",
          },
          {
            name: "Amounts",
            type: "uint256[]",
          },
        ],
      },
    ],
  },
  {
    type: "DEX",
    actions: [
      {
        name: "Set Swap Fee",
        contract: "UniswapV2Pair",
        requireAddress: true,
        function: "setSwapFee",
        arguments: [
          {
            name: "Fee",
            type: "uint256",
          },
        ],
      },
    ],
  },
  {
    type: "Farm",
    actions: [
      {
        name: "Add pool",
        contract: "MasterChef",
        function: "add",
        arguments: [
          {
            name: "Allocation Point",
            type: "uint256",
          },
          {
            name: "LP Token",
            type: "address",
          },
          {
            name: "Loan",
            type: "address",
            defaultValue: ethers.constants.AddressZero,
          },
        ],
      },
      {
        name: "Set Allocation Point",
        contract: "MasterChef",
        function: "set",
        arguments: [
          {
            name: "Pool ID",
            type: "uint256",
          },
          {
            name: "Allocation Point",
            type: "uint256",
          },
        ],
      },
    ],
  },
  {
    type: "MultiSigWallet",
    actions: [
      {
        name: "Add Owner",
        contract: "MultiSigWallet",
        function: "addOwner",
        arguments: [
          {
            name: "Owner",
            type: "address",
          },
        ],
      },
      {
        name: "Replace Owner",
        contract: "MultiSigWallet",
        function: "replaceOwner",
        arguments: [
          {
            name: "From",
            type: "address",
          },
          {
            name: "To",
            type: "address",
          },
        ],
      },
      {
        name: "Remove Owner",
        contract: "MultiSigWallet",
        function: "removeOwner",
        arguments: [
          {
            name: "Owner",
            type: "address",
          },
        ],
      },
      {
        name: "Add Submitter",
        contract: "MultiSigWallet",
        function: "addSubmitter",
        arguments: [
          {
            name: "Submitter",
            type: "address",
          },
        ],
      },
      {
        name: "Remove Submitter",
        contract: "MultiSigWallet",
        function: "removeSubmitter",
        arguments: [
          {
            name: "Submitter",
            type: "address",
          },
        ],
      },
      {
        name: "Change Requirement",
        contract: "MultiSigWallet",
        function: "changeRequirement",
        arguments: [
          {
            name: "No. of Owners Required",
            type: "uint255",
          },
        ],
      },
    ],
  },
];
