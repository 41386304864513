import { Transaction } from "components/Transaction";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useWalletState } from "state/wallet/hooks";

const TransactionDetailPage = () => {
  const { account } = useWalletState();
  const { id } = useParams();

  if (!account) return <></>;

  return (
    <>
      <Helmet>
        <title>Transaction - Ambidex MultiSig</title>
      </Helmet>
      <Transaction id={Number(id)} />
    </>
  );
};

export default TransactionDetailPage;
