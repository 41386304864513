import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import transactions from "./transactions/reducer";
import wallet from "./wallet/reducer";
import { api as eventApi } from "../services/events";

const store = configureStore({
  reducer: {
    transactions,
    wallet,
    [eventApi.reducerPath]: eventApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, thunk: true }).concat(
      eventApi.middleware
    ),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
