import { Button, Typography } from "@mui/material";
import IconWallet from "assets/icon_wallet.svg";
import { hashpack } from "connectors";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "state";
import { useWalletState } from "state/wallet/hooks";
import { disconnect, setAccount } from "state/wallet/reducer";

const ConnectButton = ({ disabled = false }: { disabled?: boolean }) => {
  const { account } = useWalletState();
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (account) {
      dispatch(disconnect());
    } else {
      hashpack
        .activate()
        .then(({ account, chainId }) => {
          dispatch(setAccount({ account, chainId }));
        })
        .catch((e) => console.log("Activation failed: ", e));
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        background: "linear-gradient(101.59deg, #F8F5FE 0%, #FFEBFE 100%)",
        boxShadow: "0px 8px 40px rgba(0,0,0,0.08)",
        border: "1px solid rgba(100, 55, 231, 0.16)",
        borderRadius: "8px",
        color: "#27292C",
        padding: "10px 17px",
        lineHeight: 1,
        textTransform: "none",
      }}
      disabled={disabled}
    >
      <img src={IconWallet} alt="icon-connect" />
      <Typography component="span" sx={{ ml: 2 }}>
        {account ? `Disconnect ${account}` : "Connect wallet"}
      </Typography>
    </Button>
  );
};

export default ConnectButton;
