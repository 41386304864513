import { Transactions } from "components/Transactions";
import { Helmet } from "react-helmet";
import { useWalletState } from "state/wallet/hooks";

function TransactionListPage({ status }: { status: "executed" | "pending" }) {
  const { account } = useWalletState();

  if (!account) return <></>;

  return (
    <>
      <Helmet>
        <title>Transactions - Ambidex MultiSig</title>
      </Helmet>
      <Transactions status={status} />
    </>
  );
}

export default TransactionListPage;
