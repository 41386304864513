import { AmbidexChainId } from "../constants/networks";

export const WHITELISTED_ACCOUNTS: { [chainId in AmbidexChainId]: string[] } = {
  [AmbidexChainId.HEDERA_TESTNET]: [
    "0.0.1982", // Tak
    "0.0.5619",
    "0.0.5765",
    "0.0.5709", // Tak
    "0.0.5763", // Alice
    "0.0.5771", // Bob
    "0.0.5773", // Charlie
    "0.0.14195", // Tony
    "0.0.2984771", // Josh
  ],
  [AmbidexChainId.HEDERA_MAINNET]: [
    "0.0.779559", // Tak
    "0.0.1930308", // Tak
    "0.0.875729", // Josh    
    "0.0.2000492", // Eric
  ],
};
