import { AmbidexChainId } from "./networks";

export type LoanAddresses = {
  name: string;
  loan: string;
  loanStaking: string;
  loanTreasury: string;
};

export const LOANS: { [chainId in AmbidexChainId]: LoanAddresses[] } = {
  [AmbidexChainId.HEDERA_TESTNET]: [
    {
      name: "Knightsbridge (Issuing)",
      loan: "0x00000000000000000000000000000000003835ff",
      loanStaking: "0x000000000000000000000000000000000038360C",
      loanTreasury: "0x00000000000000000000000000000000003835f1",
    },
    {
      name: "Knightsbridge (Active)",
      loan: "0x00000000000000000000000000000000003836fc",
      loanStaking: "0x0000000000000000000000000000000000383714",
      loanTreasury: "0x00000000000000000000000000000000003836d2",
    },
    {
      name: "Knightsbridge (Matured)",
      loan: "0x0000000000000000000000000000000000383791",
      loanStaking: "0x00000000000000000000000000000000003837A2",
      loanTreasury: "0x000000000000000000000000000000000038377B",
    },
  ],
  [AmbidexChainId.HEDERA_MAINNET]: [
    {
      name: "Knightsbridge",
      loan: "0x00000000000000000000000000000000001eedd5",
      loanStaking: "0x00000000000000000000000000000000001eeDDc",
      loanTreasury: "0x00000000000000000000000000000000001EEdCd",
    },
  ],
};
