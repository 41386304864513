import { Box, CssBaseline } from "@mui/material";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import React from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const { currentWalletAddress } = useMultiSigWallet();

  if (!currentWalletAddress) return <></>;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          pt: 15,
          pb: 3,
          bgcolor: "background.default",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
