import { ChangeEvent, FC, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";

export interface CreateTransactionDialogProps {
  open: boolean;
  onSubmit?: (
    contractName: string,
    functionName: string,
    contractAddress?: string,
    functionCost?: number,
    functionParams?: string
  ) => void;
  onClose?: () => void;
}

export const CreateTransactionDialog: FC<CreateTransactionDialogProps> = ({
  open,
  onSubmit,
  onClose,
}) => {
  const [contractName, setContractName] = useState<string>();
  const [contractAddress, setContractAddress] = useState<string>();
  const [functionName, setFunctionName] = useState<string>();
  const [functionCost, setFunctionCost] = useState<number>();
  const [functionParams, setFunctionParams] = useState<string>();
  const { contracts } = useMultiSigWallet();

  const handleClickSubmit = () => {
    if (contractName && functionName) {
      onSubmit?.(
        contractName,
        functionName,
        contractAddress,
        functionCost,
        functionParams
      );
      onClose?.();
    }
  };

  const handleChangeContractName = (event: ChangeEvent<HTMLInputElement>) => {
    setContractName(event.target.value);
  };

  const handleChangeContractAddress = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setContractAddress(event.target.value);
  };

  const handleChangeFunctionName = (event: ChangeEvent<HTMLInputElement>) => {
    setFunctionName(event.target.value);
  };

  const handleChangeFunctionCost = (event: ChangeEvent<HTMLInputElement>) => {
    setFunctionCost(+event.target.value);
  };

  const handleChangeFunctionParams = (event: ChangeEvent<HTMLInputElement>) => {
    setFunctionParams(event.target.value);
  };

  const contractNames = Object.keys(contracts).filter(
    (contractName) => contracts[contractName]?.functions.length
  );

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle>Fill in transaction data</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextField
            id="contract-name"
            select
            label="Contract"
            value={contractName}
            onChange={handleChangeContractName}
            sx={{ marginY: 2, minWidth: 200 }}
            fullWidth
          >
            {contractNames.map((contractName) => (
              <MenuItem value={contractName} key={contractName}>
                {contractName}
              </MenuItem>
            ))}
          </TextField>

          {contractName && !contracts[contractName].address && (
            <TextField
              value={contractAddress}
              onChange={handleChangeContractAddress}
              label="Contract Address"
              id="contract-address"
              sx={{ marginY: 2 }}
              fullWidth
            />
          )}

          {contractName && !!contractName.length && (
            <TextField
              id="function-name"
              select
              label="Function"
              value={functionName}
              onChange={handleChangeFunctionName}
              sx={{ marginY: 2 }}
              fullWidth
            >
              {contracts[contractName]?.functions.map((functionName) => (
                <MenuItem value={functionName} key={functionName}>
                  {functionName}
                </MenuItem>
              ))}
            </TextField>
          )}
          {contractName &&
            !!contractName.length &&
            functionName &&
            !!functionName.length && (
              <>
                <TextField
                  value={functionCost}
                  onChange={handleChangeFunctionCost}
                  label="Function Cost"
                  id="function-cost"
                  sx={{ marginY: 2 }}
                  fullWidth
                  type="number"
                />
                <TextField
                  value={functionParams}
                  onChange={handleChangeFunctionParams}
                  label="Function Parameters"
                  id="function-params"
                  sx={{ marginY: 2 }}
                  fullWidth
                  multiline
                />
              </>
            )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !(
              contractName &&
              !!contractName.length &&
              functionName &&
              !!functionName.length
            )
          }
          onClick={handleClickSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
