import {
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
} from "@mui/material";
import MultiSigWalletABI from "abis/MultiSigWallet.json";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ethers } from "ethers";
import { useState } from "react";
import { EventLog, useListEventsQuery } from "services/events";
import { Argument } from "components/Argument";
import Loader from "components/Loader";
import { Helmet } from "react-helmet";

dayjs.extend(localizedFormat);

const EventLogRow = ({ log }: { log: EventLog }) => {
  const { currentWalletAddress } = useMultiSigWallet();
  const iface = new ethers.utils.Interface(MultiSigWalletABI);
  const { name, args } = iface.parseLog(log);

  const transactionId =
    name === "Confirmation" || name === "Revocation"
      ? Number(args[1].toString())
      : name === "Execution" ||
        name === "ExecutionFailure" ||
        name === "RequirementChange" ||
        name === "Submission"
      ? Number(args[0].toString())
      : "";

  return (
    <TableRow
      sx={{ cursor: transactionId !== "" ? "pointer" : "not-allowed" }}
      onClick={() => {
        if (transactionId !== "") {
          window.open(`/${currentWalletAddress}/transactions/${transactionId}`);
        }
      }}
    >
      <TableCell sx={{ verticalAlign: "top" }}>{name}</TableCell>
      <TableCell sx={{ verticalAlign: "top" }}>
        {args.map((arg: any, i: number) =>
          Array.isArray(arg) ? (
            arg.map((a: any, j: number) => (
              <Argument key={`${name}-args-${i}-${j}`} arg={a} />
            ))
          ) : (
            <Argument key={`${name}-arg-${i}`} arg={arg} />
          )
        )}
      </TableCell>
      <TableCell sx={{ verticalAlign: "top" }}>
        {dayjs(Number(log.timestamp) * 1000).format("L LT")}
      </TableCell>
    </TableRow>
  );
};

const EventsListPage = () => {
  const { currentWalletAddress } = useMultiSigWallet();

  const DEFAULT_LINK = `api/v1/contracts/${currentWalletAddress}/results/logs?limit=20`;
  const [link, setLink] = useState<string | null>(DEFAULT_LINK);
  const [links, setLinks] = useState<string[]>([DEFAULT_LINK]);
  const [page, setPage] = useState<number>(0);
  const { data, isLoading, isFetching } = useListEventsQuery(link);

  if (isLoading) {
    return <Loader />;
  }

  if (!data?.logs) {
    return <div>No logs :(</div>;
  }

  return (
    <>
      <Helmet>
        <title>Events - Ambidex MultiSig</title>
      </Helmet>
      <Box sx={{ mb: 1, maxWidth: "1200px", mx: "auto", px: 3 }}>
        <Typography variant="h4" fontWeight={600} mb={3}>
          Events
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                  Event Name
                </TableCell>
                <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                  Arguments
                </TableCell>
                <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                  Timestamp
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.logs.map((log, i) => (
                <EventLogRow key={i} log={log} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <Button
            disabled={page === 0 || isFetching}
            onClick={() => {
              setLink(links[page - 1]);
              setPage(page - 1);
            }}
          >
            Prev
          </Button>
          <Button
            disabled={data.links.next === null || isFetching}
            onClick={() => {
              setPage(page + 1);
              if (data.links.next && page === links.length - 1) {
                setLinks([...links, data.links.next]);
              }
              setLink(links[page + 1] ?? data.links.next);
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EventsListPage;
