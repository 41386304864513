import { ChangeEvent, FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useMultiSigWallet } from "contexts/MultiSigWalletContext";
import { ContractAction } from "constants/actions";
import { toContractAddress, toContractId } from "utils";

export interface ContractActionDialogProps {
  open: boolean;
  contractAction?: ContractAction;
  onSubmit?: (
    contractName: string,
    functionName: string,
    contractAddress?: string,
    functionCost?: number,
    functionParams?: string
  ) => void;
  onSubmitTransferToken?: (
    accountId: string,
    amount: number,
    tokenId?: string
  ) => void;
  onClose?: () => void;
}

export const ContractActionDialog: FC<ContractActionDialogProps> = ({
  open,
  contractAction,
  onSubmit,
  onSubmitTransferToken,
  onClose,
}) => {
  const [contractAddress, setContractAddress] = useState<string>("");
  const [functionCost, setFunctionCost] = useState<number>();
  const [functionParams, setFunctionParams] = useState<string[]>();
  const { currentWalletAddress } = useMultiSigWallet();

  const [useCustomAddress, setUseCustomAddress] = useState<boolean>(false);
  const [useCustomParam, setUseCustomParam] = useState<boolean[]>();

  useEffect(() => {
    setFunctionParams(
      contractAction?.arguments?.map(
        (argument) =>
          (argument.isMultiSigWallet
            ? currentWalletAddress
            : argument.defaultValue) || ""
      )
    );

    setUseCustomAddress(!contractAction?.preDefinedAddresses?.length);

    setUseCustomParam(
      contractAction?.arguments?.map(
        (argument) => !argument.predefinedValues?.length
      )
    );
  }, [contractAction, currentWalletAddress]);

  if (!contractAction) {
    return null;
  }

  const handleClickSubmit = () => {
    if (
      contractAction.contract === "HederaTokenService" &&
      contractAction.function === "transferToken"
    ) {
      const tokenId = toContractId(functionParams?.[0]);
      const accountId = toContractId(functionParams?.[2]) || "";
      const amount = +(functionParams?.[3] || "");

      onSubmitTransferToken?.(accountId, amount, tokenId);
    } else {
      onSubmit?.(
        contractAction.contract,
        contractAction.function,
        toContractAddress(contractAddress),
        functionCost,
        functionParams?.map((param) => toContractAddress(param)).join(",")
      );
    }
    onClose?.();
  };

  const handleChangeContractAddress = (event: any) => {
    setContractAddress(event.target.value);
  };

  const handleChangeFunctionCost = (event: ChangeEvent<HTMLInputElement>) => {
    setFunctionCost(+event.target.value);
  };

  const handleChangeFunctionParam = (index: number, event: any) => {
    setFunctionParams((functionParams) =>
      functionParams?.map((param, i) =>
        i === index ? event.target.value : param
      )
    );
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle>{contractAction.name} - Transaction Details</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
          py={2}
        >
          {contractAction.requireAddress && (
            <>
              {!!contractAction.preDefinedAddresses?.length && (
                <>
                  <FormControl fullWidth disabled={useCustomAddress}>
                    <InputLabel id="contract-address-select-label">
                      {contractAction.contract}
                    </InputLabel>
                    <Select
                      value={contractAddress}
                      label={contractAction.contract}
                      labelId="contract-address-select-label"
                      onChange={handleChangeContractAddress}
                      fullWidth
                    >
                      {contractAction.preDefinedAddresses.map(
                        ({ name, address }) => (
                          <MenuItem key={name} value={address}>
                            {name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useCustomAddress}
                          onChange={(e) =>
                            setUseCustomAddress(e.target.checked)
                          }
                        />
                      }
                      label="Use custom address"
                    />
                  </FormControl>
                </>
              )}
              {useCustomAddress && (
                <TextField
                  value={contractAddress}
                  onChange={handleChangeContractAddress}
                  label={`${contractAction.contract} Address`}
                  id="contract-address"
                  fullWidth
                />
              )}
            </>
          )}
          {contractAction.requireCost && (
            <>
              <TextField
                value={functionCost}
                onChange={handleChangeFunctionCost}
                label="Function Cost"
                id="function-cost"
                sx={{ marginY: 2 }}
                fullWidth
                type="number"
              />
            </>
          )}
          {contractAction.arguments?.map((argument, index) => (
            <>
              {!!argument.predefinedValues?.length && (
                <>
                  <FormControl
                    fullWidth
                    key={argument.name}
                    disabled={useCustomParam?.[index]}
                  >
                    <InputLabel id={`${argument.name}-select-label`}>
                      {argument.name}
                    </InputLabel>
                    <Select
                      label={argument.name}
                      labelId={`${argument.name}-select-label`}
                      value={functionParams?.[index] || ""}
                      onChange={(e: SelectChangeEvent) =>
                        handleChangeFunctionParam(index, e)
                      }
                      fullWidth
                    >
                      {argument.predefinedValues.map(({ name, value }) => (
                        <MenuItem value={value} key={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useCustomParam?.[index]}
                          onChange={(e) =>
                            setUseCustomParam((useCustomParam) =>
                              useCustomParam?.map((param, i) =>
                                i === index ? e.target.checked : param
                              )
                            )
                          }
                        />
                      }
                      label="Use custom value"
                    />
                  </FormControl>
                </>
              )}
              {useCustomParam?.[index] && (
                <TextField
                  key={argument.name}
                  label={argument.name}
                  value={functionParams?.[index] || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeFunctionParam(index, e)
                  }
                  fullWidth
                  type={
                    ["int64", "uint256"].includes(argument.type)
                      ? "number"
                      : "text"
                  }
                  disabled={argument.isMultiSigWallet}
                />
              )}
            </>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            (contractAction.requireAddress && !contractAddress) ||
            (contractAction.requireCost && !functionCost)
          }
          onClick={handleClickSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
