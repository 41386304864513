import ProtectedRoute from "components/ProtectedRoute";
import Layout from "pages/Layout";
import Home from "pages/Home";
import { Transactions, Transaction, NewTransaction } from "pages/Transactions";
import { Events } from "pages/Events";
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import { MultiSigWalletProvider } from "contexts/MultiSigWalletContext";

const MultiSigWalletWrapper = () => {
  return (
    <MultiSigWalletProvider>
      <Outlet />
    </MultiSigWalletProvider>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MultiSigWalletWrapper />}>
          <Route index element={<Home />} />
          <Route path=":walletAddress" element={<Layout />}>
            <Route index element={<Navigate to="events" />} />
            <Route
              path="transactions/new"
              element={
                <ProtectedRoute>
                  <NewTransaction />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions/pending"
              element={
                <ProtectedRoute>
                  <Transactions status="pending" />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions/executed"
              element={
                <ProtectedRoute>
                  <Transactions status="executed" />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions/:id"
              element={
                <ProtectedRoute>
                  <Transaction />
                </ProtectedRoute>
              }
            />
            <Route
              path="events"
              element={
                <ProtectedRoute>
                  <Events />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
