import { createSlice } from "@reduxjs/toolkit";
import { HashConnectTypes } from "hashconnect";

import { hashpack } from "../../connectors";

export interface WalletState {
  name: string;
  account: string;
  chainId: number;
  saveData: {
    topic: string;
    pairingString: string;
    privateKey?: string;
    pairedWalletData?: HashConnectTypes.WalletMetadata;
  };
  foundExtension: boolean;
}

const initialState: WalletState = loadLocalData();

function loadLocalData(): WalletState {
  const foundData = localStorage.getItem("hashconnectData");

  if (foundData) {
    const state = JSON.parse(foundData);
    hashpack.reconnect(state.saveData);
    return { ...state, foundExtension: true };
  } else
    return {
      name: "Hashpack",
      account: "",
      chainId: 3,
      saveData: {
        topic: "",
        pairingString: "",
        privateKey: undefined,
        pairedWalletData: undefined,
      },
      foundExtension: false,
    };
}

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      const { account, chainId } = action.payload;
      state.account = account;
      state.chainId = chainId;
    },
    disconnect: (state) => {
      state.account = "";
      hashpack.clearPairings();
    },
    setWalletFound: (state) => {
      state.foundExtension = true;
    },
  },
});

export const { setAccount, disconnect, setWalletFound } = walletSlice.actions;
export const selectAccount = (state: any) => state.wallet;

export default walletSlice.reducer;
